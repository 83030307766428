import TagManager from 'react-gtm-module';

export const DATA_LAYER_NAME = 'PageDataLayer';

/**
 * Category (GtmCategory) is a Page (like Facility List), a common Component (like Sidebar, Header),
 * an overlay (like VendorDrawer, Create Facility Modal), or a section on a page (like Recommendations, Stall Schedule Carousel).
 */
export type GtmCategory =
  // Common
  | 'unknown' // default category, this means that a page has no defined config with gtm info
  | 'error'
  | 'layout'
  | 'sidebar'
  // Auth
  | 'login'
  | 'login_sso'
  | 'forgot_password'
  | 'reset_password'
  | 'landing'
  // Orders
  | 'order_header'
  | 'order_dashboard'
  | 'runner_dashboard'
  | 'runner_dashboard_edit'
  | 'kitchen_overview'
  | 'staff_count'
  | 'announcement'
  | 'order_history'
  | 'order_history_details'
  | 'status'
  // Admin
  | 'country_list'
  | 'country_add'
  | 'country_edit'
  | 'facility_list'
  | 'facility_add'
  | 'facility_edit'
  | 'partner_list'
  | 'partner_add'
  | 'partner_edit'
  | 'partner_merge_list'
  | 'partner_merge'
  | 'stall_details'
  | 'stall_schedule_add'
  | 'stall_schedule_edit'
  | 'stall_schedule_carousel'
  | 'vendor_schedule_add'
  | 'vendor_schedule_edit'
  | 'vendor_schedule_carousel'
  | 'vendor_list'
  | 'vendor_drawer'
  | 'user_list'
  | 'user_invite'
  | 'user_edit'
  | 'user_profile'
  // Cook
  | 'cook_header'
  | 'cook_header_pwa'
  | 'cooking_display'
  | 'cooking_display_ticket'
  | 'cooking_display_ticket_notes_translation'
  // Sales Dashboard
  | 'bookmark'
  | 'lead_list'
  | 'lead_edit'
  | 'lead_add'
  | 'lead_remove'
  | 'brand_list'
  | 'brand_edit'
  | 'brand_details'
  | 'brand_list_recommendation'
  | 'tableau_link';

/**
 * Actions (GtmAction) are things that user did, like clicked, submitted, etc.
 * or automated events that happen, like page loaded, color updated, notification shown, etc.
 * Follows past sense Naming convention:
 * @see https://docs.google.com/document/d/1w-zLMl84Z8niZI6rEgmjxJB619_AzX-phYi6CVBqFlw/edit
 */
export type GtmAction =
  | 'clicked'
  | 'submitted'
  | 'opened'
  | 'closed'
  | 'cancelled'
  | 'downloaded'
  | 'changed'
  | 'succeeded'
  | 'failed'
  | 'redirected'
  | 'filtered'
  | 'searched'
  | 'scrolled'
  | 'errored'
  | 'loaded'
  | 'updated'
  | 'shown'
  | 'hidden'
  | 'skipped'
  | 'received';

const nonInteractiveActions: GtmAction[] = [
  'loaded',
  'updated',
  'succeeded',
  'shown',
  'hidden',
];

export type EventParameters = Record<
  string,
  string | boolean | number | undefined
>;

/**
 * Prefer using existing event parameters over creating new ones
 * @see https://support.google.com/analytics/table/13594742?hl=en&ref_topic=13367566&sjid=13103504235370772909-EU
 */
export type CommonEventParameters = {
  /**
   * What is the action being taken on. Names or user facing text, like button text, modal title, etc
   * Don't pass high cardinality unique values as that can lead to GA4 limits being reached
   *
   * NOTE:  Available in analytics as : event_label
   * @see https://support.google.com/analytics/answer/12226705?hl=en
   */
  label?: string;
  /**
   * An ID of the entity that a user interacted with
   *
   * NOTE: Available in analytics as : content_id
   */
  id?: string;
  /**
   * The type of entity that a user interacted with
   *
   * NOTE: Available in analytics as : content_type
   */
  entity?: string;
  /**
   * Info about the entity the action is being taken on.
   *
   * NOTE: Available in analytics as : event_info
   * @example The type of schedule
   * @example The name of the tab
   *
   * Don't pass high cardinality unique values as that can lead to GA4 limits being reached
   * @see https://support.google.com/analytics/answer/12226705?hl=en
   *
   * Automatic computed values considered for certain categories
   * @see https://tagmanager.google.com/#/container/accounts/6004512962/containers/49379401/workspaces/28/variables
   */
  info?: string;
  /**
   * Measurable **numeric** values like order prep time, position of elements, etc.
   * These will be available as metrics for the event, so only averages/totals will be available and not raw values.
   *
   * NOTE: Available in analytics as : value
   */
  value?: number;
  /**
   * NOTE: Available in analytics as : search_term
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=17373492556371224167-EU&client_type=gtm#search
   */
  searchTerm?: string;
  /**
   * Pepper Order will be transformed into individual `orderId`, `vendorId`, `globalEntityId`
   */
  order?: {
    content: {
      order_id: string;
      vendor: {
        id: string;
      };
      global_entity_id: string;
    };
  };
  orderId?: string;
  vendorId?: string;
  globalEntityId?: string;
  /**
   * Only send if action is `errored`
   * Error messages
   */
  error?: unknown;
};

/**
 * GtmEvent is a type with Combination of Category (GtmCategory) and Action (GtmAction) - `${category}.${action}`
 */
export type GtmEvent = `${GtmCategory}.${GtmAction}`;

/**
 *
 * @param event GtmEvent
 *
 * Both action and category are kebab case.
 * @example `user_profile.clicked`
 *
 * DataLayer variables are set once and are used for all subsequent events until they are unset by passing undefined.
 *
 * We have 3 types of variables:
 *
 * - Global: These are set once on page load and are used for all events. They are set in `useInitGtm`.
 * @example `userId`, `userType`, `userRole`, `countryCode`, `facilityId`, `facilityType`
 *
 * @param common Common variables to be sent to GTM along with event
 * These are not global but are common for a lot of categories.
 *
 * @param categoryEventParameters Category variables to be sent to GTM along with event
 * These are specific to a Category and should be named as such, with a prefix of the category.
 * NOTE: These need to be in turn configured in GTM
 * @see https://tagmanager.google.com/?pli=1#/container/accounts/6004512962/containers/49379401/
 * @example `ticketColor`, `ticketStatus`, `ticketFilter`, etc.
 *
 *
 * ### Follows GFS Tracking Concept Guidelines
 * @see https://confluence.deliveryhero.com/x/pKpPGw
 * @see https://docs.google.com/spreadsheets/d/1UM1WIy1S0LUEOkhJeI19H9aXS55NJUKOTbVv4NESKf0/edit#gid=1164850021
 *
 * Prefer using Recommended events over creating new ones where possible
 * @see https://support.google.com/analytics/table/13594742?sjid=1363209137293817231-EU
 */
export const pushGtmTrackEvent = (
  event: GtmEvent,
  {
    id,
    entity,
    label,
    info,
    value,
    error,
    searchTerm,
    order,
    orderId,
    vendorId,
    globalEntityId,
  }: CommonEventParameters = {},
  categoryEventParameters: EventParameters = {},
): void => {
  const [eventCategory, onlyAction] = event.split('.') as [
    GtmCategory,
    GtmAction,
  ];
  const errorMessage = (error as Error)?.message || undefined;

  // NOTE: any events added here would also need to be added to tag manager and then forward to GA via triggers
  TagManager.dataLayer({
    dataLayerName: DATA_LAYER_NAME,
    dataLayer: {
      ...categoryEventParameters,
      event: 'trackevent',
      eventCategory,
      /**
       * Special handling for search events to match default GA4 events
       * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=17373492556371224167-EU&client_type=gtm#search
       */
      eventAction: onlyAction === 'searched' ? 'search' : event,
      /**
       * Ignore certain actions from being tracked as interactions
       * @see https://redfinsolutions.com/blog/meaning-google-tag-managers-non-interaction-hit-events
       */
      eventIsNonInteractionHit: nonInteractiveActions.includes(onlyAction),
      // Default to undefined to reset value for new events
      eventLabel:
        label || (onlyAction === 'errored' ? errorMessage : undefined),
      eventValue: value ?? undefined,
      eventInfo: info ?? undefined,
      contentId: id ?? undefined,
      contentType: entity ?? undefined,
      searchTerm: searchTerm ?? undefined,
      orderId: orderId || order?.content?.order_id || undefined,
      vendorId: vendorId || order?.content?.vendor?.id || undefined,
      globalEntityId:
        globalEntityId || order?.content?.global_entity_id || undefined,
      errorMessage,
    },
  });
};

export const addGtmPageView = (
  pageTitle: string,
  variables: EventParameters = {},
): void =>
  TagManager.dataLayer({
    dataLayerName: DATA_LAYER_NAME,
    dataLayer: {
      event: 'pageview',
      pageTitle,
      ...variables,
    },
  });
