import React, {
  createContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { logger } from '@pepper/logger';
import FWFMain, {
  FWFRegions,
  IFWFParams,
  FWFApiVersion,
  FWFEvent,
} from '@deliveryhero/fwf-sdk-javascript';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { config } from '../config';
import type { UserStateMaybe } from '../ssr/withPageProps';

export enum Flags {
  TrainingVideos = 'training-videos-link',
  EnableActivityStepper = 'enable-activity-stepper',
  EnablePartnerMerge = 'enable-partner-merge',
  EnableVendorOnboardings = 'enable-vendor-onboardings',
  ShowStackedOrders = 'show-stacked-orders',
  ShowFAB = 'show-fab',
}

export const fwfParams: IFWFParams = {
  region: FWFRegions.EU,
  apiVersion: FWFApiVersion.V3,
  environmentToken: config.FWF_ENV_TOKEN,
  user: {
    userId: 'default-user',
  },
};

export const fwfClient = new FWFMain(fwfParams);

fwfClient.setAppVersion(process.env.APP_VERSION || 'unknown').catch((err) => {
  logger.error({ err }, 'Error while init FwF');
});

const RELEVANT_CONTEXT_CHANGE = '@fwf/RELEVANT_CONTEXT_CHANGE';
const FLAGS_UPDATED_IN_BACKGROUND = '@fwf/FLAGS_UPDATED_IN_BACKGROUND';

export type AbTestFlagVariations = `Variation${number}` | 'Control';

export type FlagValue = string | boolean;

export type FlagVariations = Partial<Record<Flags, FWFEvent>>;

export const FwFContext = createContext<FlagVariations>({});

export function getFwfUserAttributes(state: UserStateMaybe | null) {
  if (!state?.user) {
    return {
      userId: 'default-user',
    };
  }
  const { user, facility, stall } = state;

  const country = facility?.country ?? state.country;

  return {
    stallId: stall?._id,
    userId: user._id,
    email: user.email,
    facilityId: facility?._id,
    countryId: country?._id,
    countryCode: country?.countryCode,
    facilityType: country?.facilityType || facility?.facilityType,
  };
}

async function updateAttributes(
  state: UserStateMaybe | null,
  setFlags: Dispatch<SetStateAction<FlagVariations>>,
): Promise<void> {
  try {
    if (state) {
      await fwfClient.updateUserAttributes(getFwfUserAttributes(state));
    } else {
      await fwfClient.clearUser();
      await fwfClient.updateUserAttributes(getFwfUserAttributes(state));
    }
  } catch (err) {
    logger.error({ err }, 'Error while setting FwF user attributes');
  }

  const newFlags: FlagVariations = await fwfClient.subscribeFeatures(
    Object.values(Flags),
    fwfParams.environmentToken,
  );

  setFlags((initialFlags) => {
    if (isEqual(newFlags, initialFlags) || isEmpty(newFlags)) {
      return initialFlags;
    }
    return newFlags;
  });
}

/**
 * @see https://github.com/deliveryhero/fwf-sdk-react.js/blob/dd504772a1a483ff6503f3c6cdde5cd1c4f1338f/FunWithFlags/src/FunWithFlags.js#L44-L56
 */
const init = (setFlags: Dispatch<SetStateAction<FlagVariations>>): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fwfClient.subscribeObserver((event: any) => {
    const { type, data } = event;

    switch (type) {
      case RELEVANT_CONTEXT_CHANGE:
      case FLAGS_UPDATED_IN_BACKGROUND: {
        if (isEmpty(data)) {
          break;
        }
        logger.debug({ data, type }, 'Flags updated in background');
        setFlags((oldFlags) => {
          const newFlags = {
            ...oldFlags,
            ...data,
          };
          if (isEqual(newFlags, oldFlags)) {
            return oldFlags;
          }
          return newFlags;
        });
        break;
      }
      default:
        break;
    }
  });
};

/**
 * FWF provider that sets user in client, loads all flags and subscribes to updates
 * To be used via `useFlag` hook. `Flags` enum is used to load flags.
 *
 * This is based on fwf-sdk-react.js, but instead of HoCs we use Context + Hooks
 * @see https://github.com/deliveryhero/fwf-sdk-react.js
 */
export const FwFClientProvider: React.FC<
  {
    flags?: FlagVariations;
    children?: React.ReactNode;
  } & UserStateMaybe
> = ({ children, flags: initialFlags, ...state }) => {
  const [flags, setFlags] = useState(initialFlags ?? {});

  useEffect(() => {
    init(setFlags);
  }, []);

  useEffect(() => {
    updateAttributes(state, setFlags).catch((err) => {
      logger.warn({ err }, 'Could not load flags');
    });
  }, [state]);

  return <FwFContext.Provider value={flags}>{children}</FwFContext.Provider>;
};
